h2 {
  font-size: 1.4rem;
}

.nav-bar {
  border-bottom: 1px solid #70707021;
  color: #585858;
  font-size: 18px;
  display: flex;
  min-height: 60px;
  align-items: center;
  background-color: #fdfdfd;
  justify-content: space-between;
  position: sticky;
  position: -webkit-sticky;
  position: -moz-sticky;
  position: -ms-sticky;
  position: -o-sticky;
  top: 0;
  z-index: 100;
}

/* 
  */

.nav-bar-home {
  border-bottom: 1px solid #70707021;
  color: #585858;
  font-size: 18px;
  display: flex;
  min-height: 60px;
  align-items: center;

  justify-content: space-between;
}

.nav-logo {
  max-height: 25px;
  margin-right: 5px;
}

.nav-logo-div {
  letter-spacing: 1px;
  font-weight: 600;
  font-size: 18px;
  color: #585858;
  display: flex;
  flex-direction: row;
  margin-left: 10px;
}

.shop-timer-hero {
  min-height: 8vh;

  display: flex;
  flex-direction: column-reverse;
  align-items: center;
  justify-content: center;
  font-size: 45px;
  color: #585858;

  font-weight: 600;
}

.shop-timer-div {
  text-align: center;
  display: flex;
  flex-direction: row;
  align-items: center;
  font-size: 30px;
  font-weight: 700;
}

.store-closing-text {
  font-size: 16px;
  text-align: center;
  margin: 0;
  color: #959595;
}
.store-closing-timer {
  margin: 0;
}

.stopwatch-icon {
  height: 22px;
  margin-right: 10px;
}

.nav-icon {
  display: flex;
  align-items: center;
}

.container-shop {
  display: flex;
  margin: 0 auto;
  max-width: 1300px;
  padding: 2rem 1rem;
}

.container-siderbar-wrapper {
  box-sizing: border-box;
}

.container-results {
  flex: 3 1;
  display: block;
  box-sizing: border-box;
}

.container-filters {
  flex: 1 1;
  margin-right: 60px;
  max-width: 260px;
  display: block;
  box-sizing: border-box;
}

.container-header {
  align-items: center;
  display: flex;
  justify-content: space-between;
  min-height: 50px;
  box-sizing: border-box;
}

.clear-refinements-button {
  padding: 0;
  overflow: visible;
  font: inherit;
  line-height: normal;
  color: inherit;
  background: none;
  border: 0;
  cursor: pointer;
  box-sizing: border-box;
  font-size: 0.85rem;
  user-select: none;
  color: rgba(33, 36, 61, 0.7);
}

.progressbar-container-test {
  width: 200px;
  height: 200px;
  border-radius: 50%;
}

.progress-circle-div {
  width: 125px;
  height: 125px;
  border-radius: 50%;
  position: relative;
}

.progress-div {
  width: 120px;
  height: 120px;
  font-size: 30px;
  font-weight: 600;
  color: #959595;
}

.progress-text {
  color: #959595;
  font-size: 16px;
  font-weight: 600;
}

.circle-timer {
  color: #585858;
  position: absolute;
  top: 50%;
  left: 50%;
}

.circle-countdown-logo {
  position: absolute;
  left: 50%;
  top: 50%;
  padding: 0px;
  margin: 0px;
  transform: translate(-50%, -50%);
}

.progressbar-text {
  color: #585858 !important;
}

.header-timer {
  margin: 0;
  font-weight: 600;
  font-size: 20px;
}

.header-timer-div {
  margin-right: 10px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.store-closing-header {
  font-size: 12px;
  text-align: center;
  margin: 0;
  color: #959595;
}

/* border-bottom: 1px solid #585858; */
.header-nav-link {
  margin: 0;
  height: 100%;
  display: flex;
  align-items: center;
  text-align: center;
  margin-left: 10px;
}

.header-nav-link-div {
  font-weight: 500;
  font-size: 14px;
  margin-right: 10px;
  height: 100%;
  display: flex;
  flex-direction: row;
}

.home-counter-div {
  height: calc(100vh - 60px);
  height: -webkit-calc(100vh - 60px);
  height: -moz-calc(100vh - 60px);
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  color: #585858;
}

.informed-text {
  color: #959595;
}

.email-input-div {
  margin-bottom: 100px;
}

.about-div {
  height: calc(100vh - 60px);
  height: -webkit-calc(100vh - 60px);
  height: -moz-calc(100vh - 60px);
  width: 1024px;
  display: block;
  box-sizing: border-box;
  color: #585858;
  margin-left: auto;
  margin-right: auto;
  padding-left: 120px;
  padding-right: 120px;
}

.about-div h2 {
  color: #585858 !important;
}

.about-heading {
  margin-top: 50px;
}

.css-11bc0q4 {
  color: #585858 !important;
}

.css-11bc0q4:active {
  background-color: #52525217 !important;
}

.css-11bc0q4:focus {
  box-shadow: 0 0 0 1px rgba(189, 189, 189, 0.3) !important;
}

.css-11bc0q4[aria-current="page"] {
  background-color: rgba(97, 97, 97, 0.09) !important;
}

.css-69cngj {
  color: #585858 !important;
}

.css-69cngj:focus {
  box-shadow: 0 0 0 2px rgba(134, 134, 134, 0.14),
    inset 0 0 0 1px rgba(67, 90, 111, 0.3),
    inset 0 -1px 1px 0 rgba(67, 90, 111, 0.14) !important;
}

.css-5ljhhe:focus {
  box-shadow: 0 0 0 2px rgba(134, 134, 134, 0.14),
    inset 0 0 0 1px rgba(67, 90, 111, 0.3),
    inset 0 -1px 1px 0 rgba(67, 90, 111, 0.14) !important;
}

.css-5ljhhe {
  color: #585858 !important;
}

.divider-solid {
  border: none;
  background-color: #ccc;
  color: #ccc;
  height: 1px;
}

.demo-counter-div {
  height: calc(100vh - 60px);
  height: -webkit-calc(100vh - 60px);
  height: -moz-calc(100vh - 60px);
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  color: #585858;
  font-size: 50px;
  font-weight: 600;
}
